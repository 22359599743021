import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DatenschutzPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Datenschutz" description="" pathname={location.pathname} />
      <section className="section">
        <div className="container">
          <h1 className="has-text-centered">Datenschutz</h1>
          <div
            className="marked-content"
            dangerouslySetInnerHTML={{
              __html: data.cockpitPrivacyPolicy.content.value,
            }}
          ></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Datenschutz {
    cockpitPrivacyPolicy {
      content {
        value
      }
    }
  }
`

export default DatenschutzPage
